import Vue from 'vue';
import Router from 'vue-router';

import store from '@/app/store';

Vue.use( Router );

let appRouter = new Router({
    mode: 'history',
    routes: [
        // generated-routes
        {
            path: '/',
            name: 'Landing',
            component: () =>
            {
                return import( /* webpackChunkName: "landing" */ '@/app/pages/Landing' );
            }
        },

        {
            path: '/explain',
            name: 'Explain',
            component: () =>
            {
                return import( /* webpackChunkName: "explain" */ '@/app/pages/Explain' );
            }
        },

        {
            path: '/game',
            name: 'Game',
            component: () =>
            {
                return import( /* webpackChunkName: "game" */ '@/app/pages/Game' );
            },

            beforeEnter: ( to, from, next ) =>
            {
                store.dispatch( 'entry/merge', { id: null }).then( () =>
                {
                    next();
                });
            }
        },

        {
            path: '/score',
            name: 'Score',
            component: () =>
            {
                return import( /* webpackChunkName: "score" */ '@/app/pages/Score' );
            }
        },

        {
            path: '/gegevens',
            name: 'Naw',
            component: () =>
            {
                return import( /* webpackChunkName: "naw" */ '@/app/pages/Naw' );
            }
        },

        {
            path: '/bedankt',
            name: 'Thanks',
            component: () =>
            {
                return import( /* webpackChunkName: "thanks" */ '@/app/pages/Thanks' );
            }
        },

        {
            path: '/test',
            name: 'Debug',
            component: () =>
            {
                return import( /* webpackChunkName: "gameTest" */ '@/app/pages/GameTest' );
            }

        }
    ]
});

appRouter.beforeEach( ( to, from, next ) =>
{
    // if( to.path !== '/' )
    // {
    //     next( '/' );
    // }
    if( typeof to.beforeEnter === 'function' ) // check if the route has a beforeEnter function
    {
        to.beforeEnter( to, from, next );
        return;
    }

    next();
});

export default appRouter;
