<template>
    <div class="main-layout">
        <img class="main-logo" src="/static/img/histor-logo.png" unselectable="on"/>

        <div class="content">
            <slot></slot>
        </div>
    </div>
</template>

<style lang="scss" scoped>

</style>

<script>
    export default {

    };
</script>
